import React from 'react';
import ReactDOM from 'react-dom';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const locPhone = urlParams.get('locPhone');
const phoneLink = "tel:+"+locPhone;
const Modal = ({ isShowing, hide }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay"/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className="modal" style={{fontFamily: "Montserrat", fontWeight: "500", textAlign: 'center', borderRadius: "16px" }}>
        <div className="modal-header">
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <p>
        Based on your response, your preparation may require extra care. Please call <a href={phoneLink}>{locPhone}</a> to discuss your appointment with our team.
        </p>
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;