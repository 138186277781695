import axios from 'axios';

// Changing baseURL to local since we're going to put the region select feed in the applet container.
const baseURL = '/region-feed';

const getUrgentCareRegions = async () => {
  return await axios.get(`${baseURL}/?mode=urgentcare&type=parsed`);
}

const getLabRegions = async () => {
  return await axios.get(`${baseURL}/?mode=lab&type=parsed`);
}

const getMammogramRegions = async () => {
  return await axios.get(`${baseURL}/?mode=mammogram&type=parsed`);
}

export {
  getUrgentCareRegions,
  getLabRegions,
  getMammogramRegions
}