import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  notice: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '.75rem',
    lineHeight: '150%',
    letterSpacing: '0.11px',
    color: '#B2201B',
    marginLeft: '0.5rem',
  },
}));
const Notice = ({ isShowing }) => {
  const classes = useStyles();
  return (
    <div>
      {isShowing ? (
        <Typography variant="subtitle2" className={classes.notice}>
          Online scheduling is not available for this response. Please call
          833-881-9081 to schedule an appointment.
        </Typography>
      ) : null}
    </div>
  );
};

export default Notice;
