import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '../../../node_modules/centracare-scheduling/registry/css/index.css';

const useStyles = makeStyles(
  () => ({
    header: {
      fontFamily: 'Montserrat',
      margin: '20px 0.5rem',
      fontSize: '1.5rem',
      fontWeight: '400',
      height: 10,
      textAlign: 'left',
    },
    mainContentContainer: {
      fontFamily: 'Montserrat',
      margin: '0 auto',
      maxWidth: '1232px',
      padding: '0 16px',
    },
    duelContainer: {
      fontFamily: 'Montserrat',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '100px 0px',
    },
    cardStyles: {
      fontFamily: 'Montserrat',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      fontFamily: 'Montserrat',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    iconStyle: {
      fontFamily: 'Montserrat',
      color: '#006298',
      margin: '0 auto',
      fontSize: '30px',
    },
    pStyle: {
      fontFamily: 'Montserrat',
      textAlign: 'center',
      fontWeight: '500',
    },
    button: {
      fontFamily: 'Montserrat',
      backgroundColor: '#006298',
      color: 'white',
    },
  }),
  {
    name: 'hwui-UrgentCareVisitSelect',
  }
);

const OnMyWay = ({ history }) => {
  const classes = useStyles();

  return (
    <div className={classes.mainContentContainer}>
      <Typography variant="h1" className={classes.header}>
        Find Care Now
      </Typography>
      <p>
        <small>
          If this is a medical emergency, call 911 or go to the nearest
          emergency room.
        </small>
      </p>
      {/* <div className={classes.duelContainer}>
            <Card className={classes.cardStyles} variant='outlined' >
                <CardContent className={classes.content}>
                    <p className={classes.pStyle}>I need to come in as soon as possible (within 48 hours)</p>
                    <Button 
                        variant='contained'
                        onClick={() => history.push('/find-care/emergency-room')}  
                        className={classes.button}>
                            I&apos;m On My Way
                    </Button>
                </CardContent>
            </Card>
            <Card className={classes.cardStyles} variant='outlined' >
                <CardContent className={classes.content}>

                    <Typography 
                        component='p' 
                        className={classes.pStyle}>
                            I need to schedule a time in the future (after 48 hours)
                    </Typography>
                    <Button 
                        variant='contained' 
                        onClick={() => history.push('/find-care/UrgentCare-DirectScheduling')} 
                        className={classes.button}>
                            Schedule Appointment
                    </Button>
                </CardContent>
            </Card>
            </div>    */}
    </div>
  );
};

OnMyWay.propTypes = {
  history: PropTypes.object,
};

export default withRouter(OnMyWay);
