import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Link,

} from '@material-ui/core';

import SchedulingModal from '../../OpenScheduling/SchedulingModal';

export const RegionTypes = {
  "urgentcare": "urgentcare",
  "mammogram": "mammogram",
  "lab": "lab"
};

const getTarget = () => {
  // Guest embeds ought not have target _blank.
  let checkparams = new URLSearchParams(window.location.search);
  let checkorigin = checkparams.get('from');
  let linktarget = '_blank';
  if (checkorigin === 'guest') {
    // Should be _self but temporarily setting to _parent.
    linktarget = '_parent';
  };
  return linktarget;
}

const useStyles = makeStyles(() => ({
  accordion: {
    fontFamily: 'Montserrat',
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  accordionSummary: {
    fontFamily: 'Montserrat',
    borderBottom: `1px solid #E9E9E9`,
    height: 50
  },
  subAccordion: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
    marginLeft: 20
  },
  regionContainer: {
    fontFamily: 'Montserrat',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  typographyRegionName: {
    fontFamily: 'Montserrat',
    fontSize: '1.4rem',
    fontWeight: '700',
  },
  typographySubRegionName: {
    fontFamily: 'Montserrat',
    margin: '10px 0px',
    fontSize: '1.3rem',
  },
  typographyLocalName: {
    fontFamily: 'Montserrat',
    margin: '10px 0px',
    fontSize: '1.3rem',
    color: '#006298'
  },
}), { name: 'hwui-RegionAccordion', });

let selectedDate = null;
let selectedLocation = null;
const epicGoLiveDate = new Date('03/06/2022');
const showDatePickerStartDate = new Date('02/22/2022');
const showDatePickerEndDate = new Date('03/06/2022');

const RegionAccordion = ({ isDirect, regionList, type }) => {
  // Used to hide and show the modal for the date of appointment
  const [showModal, setShowModal] = useState(false);


  const subRegionClicked = (locationData) => {
    setShowModal(true);
    selectedLocation = locationData;
  }

  const handleClose = (userSelectedDate) => {
    setShowModal(false);
    if (userSelectedDate) {
      selectedDate = userSelectedDate;
      const rerouteLink = getLink(selectedLocation);
      // Reroute to the new url in a new tab
      window.open(rerouteLink, getTarget());
    }
  };

  const getLink = ({
    epic_visit_type_open,
    epic_visit_type_direct,
    epic_department_id,
    fac_type,
    cerner_url
  }) => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const projectEnvironment = urlParams.get('env');
    const projectMode = urlParams.get('mode');
    const urldata = {};



    if (projectEnvironment) {
      urldata.env = projectEnvironment;
    };

    if (projectMode && projectMode === 'auth') {
      urldata.mode = 'auth';
    };

    const addParams = new URLSearchParams(urldata).toString();

    switch (type) {
      case RegionTypes.urgentcare:
        return getUrgentcareLink({
          addParams,
          epicVisitTypeOpen: epic_visit_type_open,
          epicVisitTypeDirect: epic_visit_type_direct,
          epicDepartmentId: epic_department_id,
          factype: fac_type,
          cernerurl: cerner_url
        });
      case RegionTypes.mammogram:
        return getMammogramLink({
          projectMode,
          projectEnvironment,
          addParams,
          epicVisitTypeOpen: epic_visit_type_open,
          epicVisitTypeDirect: epic_visit_type_direct,
          epicDepartmentId: epic_department_id,
          factype: fac_type,
          cernerurl: cerner_url
        });
      case RegionTypes.lab:
        return getLabLink({
          projectMode,
          projectEnvironment,
          epicVisitTypeOpen: epic_visit_type_open,
          epicVisitTypeDirect: epic_visit_type_direct,
          epicDepartmentId: epic_department_id,
          factype: fac_type,
          cernerurl: cerner_url
        });
      default:
        return null;
    };
  };


  const getUrgentcareLink = ({
    addParams,
    epicVisitTypeOpen,
    epicVisitTypeDirect,
    epicDepartmentId,
    factype,
    cernerurl
  }) => {

    // If the location selected is in wave one then check the appoinment date against the epic go live date
    if (factype.toLowerCase() === 'epic') {
      const today = new Date();

      // If the user selects an appointment date after the epic go live date
      // Return the epic link
      if (selectedDate >= epicGoLiveDate || today >= showDatePickerEndDate) {
        return isDirect ? `/find-care/UrgentCareVisitSelectDirectScheduling?selRfvId=${epicVisitTypeDirect}&selDepId=${epicDepartmentId}&${addParams}`
          : `/find-care/scheduling/urgentcare-visit-select?vt=${epicVisitTypeOpen}&dept=${epicDepartmentId}&${addParams}`;
      }
      else {
        // Return the Cerner link
        return cernerurl;
      }
    }
    else {
      // Return the Cerner link
      return cernerurl;
    }

  };

  const getMammogramLink = ({
    projectMode,
    projectEnvironment,
    epicVisitTypeOpen,
    epicVisitTypeDirect,
    epicDepartmentId,
    factype,
    cernerurl
  }) => {
    const domain = getEpicLinkByEnvironment({ projectEnvironment });
    const mode = (projectMode && projectMode === 'auth') ? 'find-care' : 'public';
    const schedulingType = (projectMode && projectMode === 'auth') ? 'direct-scheduling' : 'open-scheduling';
    const mammovars = (projectMode && projectMode === 'auth') ? `selRfvId=${epicVisitTypeDirect}&selDepId=${epicDepartmentId}` : `vt=${epicVisitTypeOpen}`;

    // Get MyChart Enironment for link.
    let myChartEnv =
    projectEnvironment && projectEnvironment === 'tst'
        ? 'MyChartTSTDEV'
        : projectEnvironment && projectEnvironment === 'poc'
          ? 'MyChartPOCDEV'
          : projectEnvironment && projectEnvironment === 'prod'
            ? 'MyChartPRDDev'
            : 'MyChartPRDDev';

    // If the location selected is in wave one then check the appoinment date against the epic go live date
    if (factype.toLowerCase() === 'epic') {

      const today = new Date();

      // If the user selects an appointment date after the epic go live date
      // Return the epic link
      if (selectedDate >= epicGoLiveDate || today >= showDatePickerEndDate) {
        return (isDirect || (projectMode && projectMode === 'auth')) ? `${domain}/find-care/scheduling/direct-scheduling/mammogram?selRfvId=${epicVisitTypeDirect}&selDepId=${epicDepartmentId}`
          : `${domain}/${myChartEnv}/openscheduling/standalone?id=40&${mammovars}`;
      }
      else {
        // Return the Cerner link
        return cernerurl;

      }
    }
    else {
      // Return the Cerner link
      return cernerurl;
    }
  };

  const getLabLink = ({
    projectMode,
    projectEnvironment,
    epicVisitTypeOpen,
    epicVisitTypeDirect,
    epicDepartmentId,
    factype,
    cernerurl
  }) => {
    const domain = getEpicLinkByEnvironment({ projectEnvironment });
    const mode = (projectMode && projectMode === 'auth') ? 'find-care' : 'public';
    const schedulingType = (projectMode && projectMode === 'auth') ? 'direct-scheduling' : 'open-scheduling';
    const labvars = (projectMode && projectMode === 'auth') ? `selRfvId=${epicVisitTypeDirect}&selDepId=${epicDepartmentId}` : `vt=${epicVisitTypeOpen}&dept=${epicDepartmentId}`;

    // Get MyChart Enironment for link.
    let myChartEnv =
    projectEnvironment && projectEnvironment === 'tst'
        ? 'MyChartTSTDEV'
        : projectEnvironment && projectEnvironment === 'poc'
          ? 'MyChartPOCDEV'
          : projectEnvironment && projectEnvironment === 'prod'
            ? 'MyChartPRDDev'
            : 'MyChartPRDDev';

    // If the location selected is in wave one then check the appoinment date against the epic go live date
    if (factype.toLowerCase() === 'epic') {

      const today = new Date();

      // If the user selects an appointment date after the epic go live date
      // Return the epic link
      if (selectedDate >= epicGoLiveDate || today >= showDatePickerEndDate) {
        return (isDirect || (projectMode && projectMode === 'auth')) ? `${domain}/find-care/scheduling/direct-scheduling/labs?selRfvId=${epicVisitTypeDirect}&selDepId=${epicDepartmentId}`
          : `${domain}/${myChartEnv}/openscheduling/?specialty=89&${labvars}`;
      }
      else {
        // Return the Cerner link
        return cernerurl;

      }
    }
    else {
      // Return the Cerner link
      return cernerurl;
    }
  };

  const getEpicLinkByEnvironment = ({ projectEnvironment }) => {
    switch (projectEnvironment) {
      case 'tst':
        return 'https://testing-account.adventhealth.com';
      case 'poc':
        return 'https://environment-epic-account.dev.adventhealth.io';
      case 'prod':
        return 'https://account.adventhealth.com';
      default:
        return 'https://account.adventhealth.com';
    };
  };


  const showDatePickerModal = (fac_type) => {
    const today = new Date();
    if (fac_type === 'epic' && today >= showDatePickerStartDate && today <= showDatePickerEndDate) {
      return true;
    }
    else {
      return false;
    }
  }

  const classes = useStyles();
  return regionList && regionList.map(({ region_name, sub_region_list }) => (
    <Box key={region_name}>
      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          className={classes.accordionSummary}>
          <Typography variant="h3" className={classes.typographyRegionName}>{region_name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {
            (type === RegionTypes.urgentcare || type === RegionTypes.mammogram) ?
              <Box className={classes.regionContainer}>
                {sub_region_list && sub_region_list.map(({ sub_region_name, locations, }, index) => (
                  <Box key={index} className={classes.subAccordion}>
                    {
                      showDatePickerModal(locations[0].fac_type.toLowerCase()) ?

                        // If Epic, then show the date modal
                        <Link onClick={() => {
                          const locationData = locations[0];
                          subRegionClicked(locationData);
                        }}
                          color="primary"
                        >
                          <Typography className={classes.typographyLocalName}>{sub_region_name}</Typography>
                        </Link> :
                        <Link href={getLink(locations[0])}
                          target={getTarget()}
                          color="primary"

                        >
                          <Typography className={classes.typographyLocalName}>{sub_region_name}</Typography>
                        </Link>
                    }
                  </Box>
                ))}
              </Box> :
              <Box className={classes.regionContainer}>
                {sub_region_list && sub_region_list.map(({ sub_region_name, locations }) => (
                  <Accordion className={classes.subAccordion} key={sub_region_name}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      className={classes.accordionSummary}>
                      <Typography variant="h3" className={classes.typographySubRegionName}>{sub_region_name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Box display="flex" flexDirection="column">
                        {locations && locations.map(({ loc_name_friendly, epic_department_id, epic_visit_type_open, epic_visit_type_direct, fac_type, cerner_url }, index) => (
                          <Box key={index}>
                            {
                              showDatePickerModal(fac_type.toLowerCase()) ?

                                // If Epic, then show the date modal
                                <Link onClick={() => {
                                  const locationData = { loc_name_friendly, epic_department_id, epic_visit_type_open, epic_visit_type_direct, fac_type, cerner_url };
                                  subRegionClicked(locationData);
                                }}
                                  color="primary"
                                >
                                  <Typography className={classes.typographyLocalName}>{loc_name_friendly}</Typography>
                                </Link>
                                :

                                // If not epic then just reroute to the cerner url
                                <Link href={getLink({
                                  epic_visit_type_open,
                                  epic_visit_type_direct,
                                  epic_department_id,
                                  fac_type,
                                  cerner_url
                                })}
                                  target={getTarget()}
                                  color="primary"

                                >
                                  <Typography className={classes.typographyLocalName}>{loc_name_friendly}</Typography>
                                </Link>

                            }

                          </Box>
                        ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>

          }

        </AccordionDetails>
      </Accordion>
      {showModal ? <SchedulingModal open={showModal} onClose={handleClose} /> : null}

    </Box>
  ));
}

RegionAccordion.propTypes = {
  isDirect: PropTypes.bool.isRequired,
  regionList: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
}

export default RegionAccordion;