import './App.css';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import UrgentCareRegionSelectOpenScheduling from './pages/OpenScheduling/UrgentCareRegionSelectOpenScheduling';
import LabRegionSelectOpenScheduling from './pages/OpenScheduling/LabRegionSelectOpenScheduling';
import MammogramQuestionnaireOpenScheduling from './pages/OpenScheduling/MammogramQuestionnaireOpenScheduling';
import OpenScheduling from './pages/OpenScheduling/OpenScheduling';
import UrgentCareVisitSelectOpenScheduling from './pages/OpenScheduling/UrgentCareVisitSelectOpenScheduling';
import OnMyWay from './pages/OpenScheduling/OnMyWay';
import MammoOpenScheduling from './pages/OpenScheduling/MammogramOpenScheduling';
import LabMammoDirectScheduling from './pages/DirectScheduling/LabMammoDirectScheduling';
import LabRegionSelectDirectScheduling from './pages/DirectScheduling/LabRegionSelectDirectScheduling';
import LabDirectScheduling from './pages/DirectScheduling/LabDirectScheduling';
import MammoQuestionaireDirectScheduling from './pages/DirectScheduling/MammoQuestionaireDirectScheduling';
import MammoRegionSelectDirectScheduling from './pages/DirectScheduling/MammoRegionSelectDirectScheduling';
import MammoDirectScheduling from './pages/DirectScheduling/MammoDirectScheduling';
import UrgentCareVisitSelectDirectScheduling from './pages/DirectScheduling/UrgentCareVisitSelectDirectScheduling';
import UrgentCareDirectScheduling from './pages/DirectScheduling/UrgentCareDirectScheduling';
import UrgentCareRegionSelectDirectScheduling from './pages/DirectScheduling/UrgentCareRegionSelectDirectScheduling';
import LabOpenScheduling from './pages/OpenScheduling/LabOpenScheduling'
import MammogramOpenScheduling from './pages/OpenScheduling/MammogramOpenScheduling'
import MammogramRegionSelectOpenScheduling from './pages/OpenScheduling/MammogramRegionSelectOpenScheduling'
import LabMammoOpenScheduling from './pages/OpenScheduling/LabMammoOpenScheduling';

function App() {
  return (
    // Build URL params for links.
    // TODO: FIgure out why the selectedPhrId param is not being properly passed in test environments.
    // The mode query param ought not be necessary.const queryString = window.location.search;

    <div className="App">
      <BrowserRouter>
        <Switch>
          {/* Open Scheduling */}
          <Route path="/find-care/LabMammo">
            <LabMammoOpenScheduling/>
          </Route>
          <Route path="/find-care/Lab">
            <LabRegionSelectOpenScheduling/>
          </Route>
          <Route path="/find-care/LabOpenScheduling">
            <LabOpenScheduling/>
          </Route>
          <Route path="/find-care/Mammo">
            <MammogramRegionSelectOpenScheduling/>
          </Route>
          <Route path="/find-care/MammoOpenScheduling">
            <MammogramOpenScheduling/>
          </Route>
          <Route path="/find-care/MammogramQuestionnaireOpenScheduling">
            <MammogramQuestionnaireOpenScheduling/>
          </Route>
          <Route path="/find-care/scheduling/mammogram-visit-select">
            <MammoOpenScheduling/>
          </Route>
          <Route path="/find-care/UrgentCare">
            <UrgentCareRegionSelectOpenScheduling/>
          </Route>
          <Route path="/find-care/scheduling/urgentcare-visit-select">
            <UrgentCareVisitSelectOpenScheduling/>
          </Route>
          <Route path="/find-care/urgent-care">
            <OpenScheduling/>
          </Route>
          <Route path="/find-care/emergency-room">
            <OnMyWay/>
          </Route>
          {/* Direct Scheduling */}
          <Route path="/find-care/LabMammoDirectScheduling">
            <LabMammoDirectScheduling/>
          </Route>
          <Route path="/find-care/LabRegionSelectDirectScheduling">
            <LabRegionSelectDirectScheduling/>
          </Route>
          <Route path="/find-care/LabDirectScheduling">
            <LabDirectScheduling/>
          </Route>
          <Route path="/find-care/MammoQuestionaireDirectScheduling">
            <MammoQuestionaireDirectScheduling/>
          </Route>
          <Route path="/find-care/MammoRegionSelectDirectScheduling">
            <MammoRegionSelectDirectScheduling/>
          </Route>
          <Route path="/find-care/MammoDirectScheduling">
            <MammoDirectScheduling/>
          </Route>
          <Route path="/find-care/UrgentCareDirectScheduling">
            <UrgentCareRegionSelectDirectScheduling/>
          </Route>
          <Route path="/find-care/UrgentCareVisitSelectDirectScheduling">
            <UrgentCareVisitSelectDirectScheduling/>
          </Route>
          <Route path="/find-care/UrgentCare-DirectScheduling">
            <UrgentCareDirectScheduling/>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
