import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import "../../../node_modules/centracare-scheduling/registry/css/index.css"
import { useGetMammogramRegions } from '../../hooks/useGetMammogramRegions';
import RegionAccordion, { RegionTypes } from '../components/RegionAccordion/RegionAccordion';

const useStyles = makeStyles(() => ({
    header: {
        fontFamily: 'Montserrat',
        margin: '20px 0.5rem',
        fontSize: '1.5rem',
        fontWeight: '400',
        height: 10,
        textAlign: 'left',
    },
    mainContentContainer: {
        fontFamily: 'Montserrat',
        margin: '0 auto',
        maxWidth: '1232px',
        padding: '0 16px',
    }
}), { name: 'hwui-MammogramScheduling', });

const MammoRegionSelectOpenScheduling = () => {
    const classes = useStyles();

    const { regions } = useGetMammogramRegions();

    return (
        <Box className={classes.mainContentContainer}>
            <Typography variant='h1' className={classes.header}>Choose a Location</Typography>
            <RegionAccordion
                regionList={regions}
                isDirect={false}
                type={RegionTypes.mammogram} />
        </Box>
    );
};

MammoRegionSelectOpenScheduling.propTypes = {
    history: PropTypes.object
}

export default MammoRegionSelectOpenScheduling;