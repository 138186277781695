import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../../Modal';
import useModal from '../../useModal';
import ButtonGroup from '../../ButtonGroup';

const useStyles = makeStyles(
  () => ({
    header: {
      fontFamily: 'Montserrat',
      margin: '20px 0.5rem 16px 0.5rem',
      fontSize: '1.5rem',
      fontWeight: '400',
      textAlign: 'left',
    },
    section: {
      fontFamily: 'Montserrat',
      margin: '20px 0.5rem 8px 0.5rem',
      fontSize: '1rem',
      fontWeight: '500',
      textAlign: 'left',
    },
    notice: {
      fontFamily: 'Montserrat',
      margin: '0px 0.5rem 20px 0.5rem',
      fontSize: '1rem',
      fontWeight: '500',
      textAlign: 'left',
      '@media (min-width: 768px)': {
        float: 'right',
      },
    },
    mainContentContainer: {
      fontFamily: 'Montserrat',
      margin: '0 auto',
      maxWidth: '1232px',
      padding: '0 16px',
    },
    duelContainer: {
      fontFamily: 'Montserrat',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '100px 0px',
    },
    cardStyles: {
      fontFamily: 'Montserrat',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      fontFamily: 'Montserrat',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    iconStyle: {
      fontFamily: 'Montserrat',
      color: '#006298',
      margin: '0 auto',
      fontSize: '30px',
    },
    pStyle: {
      fontFamily: 'Montserrat',
      textAlign: 'center',
      fontWeight: '500',
    },
    input: {
      fontFamily: 'Montserrat',
      backgroundColor: '#FFFFFF',
      color: '#006298',
      borderColor: '#006298',
      borderStyle: 'solid',
      padding: '8px 16px',
      borderRadius: '16px',
      margin: '4px 2px',
      '&:hover': {
        backgroundColor: '#eef7fc',
        color: '#179bd3',
        borderColor: '#179bd3',
      },
    },
    submitButton: {
      fontFamily: 'Montserrat',
      backgroundColor: '#006298',
      color: '#FFFFFF',
      borderColor: '#006298',
      borderStyle: 'solid',
      padding: '8px 16px',
      borderRadius: '4px',
      margin: '4px 2px',
      float: 'right',
      '&:disabled': {
        color: 'red',
      },
    },
    formcontainer: {
      border: '1px',
      borderStyle: 'solid',
      borderRadius: '16px',
    },
  }),
  {
    name: 'hwui-UrgentCareVisitSelect',
  }
);

const MammoQuestionaireDirectScheduling = () => {
  const classes = useStyles();
  const { isShowing, toggle } = useModal();
  const [isDisabled, setIsDisabled] = useState(true);

  let questionArray = [false, false, false, false, false, false, false];

  const handleQuestionOneClick = (e) => {
    if (e.target.name === 'Male') {
      toggle();
      questionArray[0] = false;
      checkSurvey(questionArray);
    } else {
      questionArray[0] = true;
      checkSurvey(questionArray);
    }
  };

  const handleQuestionTwoClick = (e) => {
    if (e.target.name === 'Less than 30 years') {
      toggle();
      questionArray[1] = false;
      checkSurvey(questionArray);
    } else {
      questionArray[1] = true;
      checkSurvey(questionArray);
    }
  };

  const handleQuestionThreeClick = (e) => {
    if (e.target.name === 'No') {
      toggle();
      questionArray[2] = false;
      checkSurvey(questionArray);
    } else {
      questionArray[2] = true;
      checkSurvey(questionArray);
    }
  };

  const handleQuestionFourClick = (e) => {
    if (e.target.name === 'No') {
      toggle();
      questionArray[3] = false;
      checkSurvey(questionArray);
    } else {
      questionArray[3] = true;
      checkSurvey(questionArray);
    }
  };

  const handleQuestionFiveClick = (e) => {
    if (e.target.name === 'No' || e.target.name === 'Unknown') {
      toggle();
      questionArray[4] = false;
      checkSurvey(questionArray);
    } else {
      questionArray[4] = true;
      checkSurvey(questionArray);
    }
  };

  const handleQuestionSixClick = (e) => {
    if (e.target.name === 'Yes') {
      questionArray[5] = true;
      console.log(exitURL);
      checkSurvey(questionArray);
    } else {
      questionArray[5] = true;
      console.log(exitURL);
      checkSurvey(questionArray);
    }
  };

  const handleQuestionSevenClick = (e) => {
    if (e.target.name === 'Less than 1 year') {
      toggle();
      questionArray[6] = false;
      checkSurvey(questionArray);
    } else {
      questionArray[6] = true;
      checkSurvey(questionArray);
    }
  };

  const checkSurvey = () => {
    console.log(projectEnvironmnet);
    console.log(questionArray);
    if (questionArray !== [true, true, true, true, true, true, true]) {
      setIsDisabled(true);
      console.log(isDisabled);
      console.log('Button is disabled');
    } else {
      setIsDisabled(false);
      console.log(isDisabled);
      console.log('Button is enabled');
    }
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const projectEnvironmnet = urlParams.get('env');

  const selRfvId = urlParams.get('selRfvId');
  const selDepId = urlParams.get('selDepId');

  let domain =
    projectEnvironmnet && projectEnvironmnet === 'tst'
      ? 'https://testing-account.adventhealth.com'
      : projectEnvironmnet && projectEnvironmnet === 'poc'
      ? 'https://environment-epic-account.dev.adventhealth.io'
      : projectEnvironmnet && projectEnvironmnet === 'prod'
      ? 'https://account.adventhealth.com'
      : 'https://account.adventhealth.com';

  // let exitURL = `http://localhost:8000/find-care/scheduling/direct-scheduling/mammogram?selRfvId=${mammoVisitType}&selDepId=1002007042`;
  // let exitURL = `http://${domain}/public/scheduling/open-scheduling/mammogram?selRfvId=9&selDepId=1002007042`;

  // let exitURL = `http://localhost:8000/public/scheduling/open-scheduling/mammogram?vt=${mammoVisitType}&dept=1002007042`;
  let exitURL = `${domain}/find-care/scheduling/direct-scheduling/mammogram?selRfvId=${selRfvId}&selDepId=${selDepId}`;

  return (
    <div className={classes.mainContentContainer}>
      <Typography variant="h1" className={classes.header}>
        Mammogram Questionnaire
      </Typography>
      <p>Lorem ipsum</p>
      <Typography
        variant="h3"
        className={classes.section}
        style={{ float: 'right' }}
      >
        <span style={{ color: 'red' }}>*</span>Indicates a required field
      </Typography>
      <Typography variant="h2" className={classes.header}>
        Patient information
      </Typography>
      <br />
      <div className={classes.formcontainer}>
        <p>{domain}</p>

        <Typography variant="h3" className={classes.section}>
          <span style={{ color: 'red' }}>*</span>What is your legal sex?
        </Typography>
        <ButtonGroup
          buttons={['Male', 'Female']}
          onButtonClick={handleQuestionOneClick}
        />

        <Typography variant="h3" className={classes.section}>
          <span style={{ color: 'red' }}>*</span>How old are you?
        </Typography>
        <ButtonGroup
          buttons={[
            'Greater than 40 years old',
            'Less than 40 years old',
            'Less than 30 years',
          ]}
          onButtonClick={handleQuestionTwoClick}
        />

        <Typography variant="h3" className={classes.section}>
          <span style={{ color: 'red' }}>*</span>Do you have an order for this
          screening mammogram?
        </Typography>
        <ButtonGroup
          buttons={['Yes', 'No']}
          onButtonClick={handleQuestionThreeClick}
        />

        <Typography variant="h3" className={classes.section}>
          <span style={{ color: 'red' }}>*</span>Do you have a breast mass,
          lump, discharge, or pain?
        </Typography>
        <ButtonGroup
          buttons={['Yes', 'No']}
          onButtonClick={handleQuestionFourClick}
        />

        <Typography variant="h3" className={classes.section}>
          <span style={{ color: 'red' }}>*</span>Are you Considered High Risk of
          Breast Cancer by your physician?
        </Typography>

        <ButtonGroup
          buttons={['Yes', 'No', 'Unknown']}
          onButtonClick={handleQuestionFiveClick}
        />

        <Typography variant="h3" className={classes.section}>
          <span style={{ color: 'red' }}>*</span>Do you have breast implants?
        </Typography>
        <ButtonGroup
          buttons={['Yes', 'No']}
          onButtonClick={handleQuestionSixClick}
        />

        <Typography variant="h3" className={classes.section}>
          <span style={{ color: 'red' }}>*</span>What was the date of your last
          mammogram?
        </Typography>
        <ButtonGroup
          buttons={['Less than 1 year', 'Greater than 1 year']}
          onButtonClick={handleQuestionSevenClick}
        />
        <br />
      </div>
      <a className={classes.submitButton} href={exitURL} target="_top">
        Submit and continue
      </a>
      <Modal isShowing={isShowing} hide={toggle} />
    </div>
  );
};

export default MammoQuestionaireDirectScheduling;
