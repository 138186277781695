import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import "../../../node_modules/centracare-scheduling/registry/css/index.css"

import { useGetUrgentCareRegions } from '../../hooks/useGetUrgentCareRegions';
import RegionAccordion, { RegionTypes } from '../components/RegionAccordion/RegionAccordion';

const useStyles = makeStyles(() => ({
    header: {
        fontFamily: 'Montserrat',
        margin: '20px 0.5rem',
        fontSize: '1.5rem',
        fontWeight: '400',
        height: 10,
        textAlign: 'left',
        color: '#192129',
    },
    mainContentContainer: {
        fontFamily: 'Montserrat',
        margin: '0 auto',
        maxWidth: '1232px',
        padding: '0 16px',
    },
}), { name: 'hwui-UrgentCareScheduling', });

const UrgentCareRegionSelectOpenScheduling = () => {
    const classes = useStyles();
    const { regions } = useGetUrgentCareRegions();

    return (
        <Box className={classes.mainContentContainer}>
            <Typography variant='h1' className={classes.header}>Choose a Location</Typography>
            <p>
                <small>
                    AdventHealth is dedicated to delivering 24/7 access for all of your healthcare needs. We
                    invite you to scheduled an online reservation with AdventHealth&apos;s award winning services
                    by selecting an option below to begin.
                </small>
            </p>
            <RegionAccordion
                regionList={regions}
                isDirect={false}
                type={RegionTypes.urgentcare} />
        </Box>
    );
};

UrgentCareRegionSelectOpenScheduling.propTypes = {
    history: PropTypes.object
}

export default UrgentCareRegionSelectOpenScheduling;