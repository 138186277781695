import { useState, useEffect } from 'react';

import { getLabRegions } from '../services/regions';

export const useGetLabRegions = () => {

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [regions, locsbuilt] = useState([]);

  useEffect(()=> {
    const getLabRegionsFetch = async() => {
      try{
        setLoading(true);
        const regions = await getLabRegions();
        locsbuilt(regions.data);
      }catch(err){
        setError(err);
      }finally{
        setLoading(false);
      }
    }
    getLabRegionsFetch();
  }, []);

  return {
    loading, 
    error, 
    regions
  }
}