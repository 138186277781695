import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  createMuiTheme,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  ThemeProvider
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';


function SchedulingModal(props) {

  const { onClose, open } = props;

  const cancel = () => {
    onClose();
  };

  const save = () => {
    onClose(selectedDate);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [selectedDate, setSelectedDate] = useState(new Date());


  const theme = createMuiTheme({
    palette: {
      primary: {
        main: "#006298",
        contrastText: "#fff" //button text white instead of black
      },
    }
  });


  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });




  return (
    <ThemeProvider theme={theme}>

      <Dialog maxWidth={'sm'} fullWidth onClose={cancel} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle onClose={cancel} style={{ borderBottom: '1px solid #DAE2EA', color: 'rgb(25, 33, 41)' }}>
          How soon would you like to schedule?
        </DialogTitle>
        <DialogContent>
          <p style={{ color: 'rgb(25, 33, 41)', marginLeft: '0px' }}>
            Select a start date to search for available appointments
          </p>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>

            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Appointment Date"
              minDate={new Date()}
              initial
              minDateMessage={"Schedule date can not be before today's date"}
              invalidDateMessage={"The date must be in the format MM/DD/YYYY"}
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              variant="inline"

            />
          </MuiPickersUtilsProvider>

        </DialogContent>
        <DialogActions style={{ borderTop: '1px solid #DAE2EA', padding: '20px' }}>

          <Button onClick={cancel} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={save} color="primary" variant="contained" disabled={selectedDate == null}>
            Continue
          </Button>

        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
SchedulingModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default SchedulingModal;