import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import styles from './styles.module.css';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  button: {
    border: '1px solid #758BA0',
    boxSizing: 'border-box',
    borderRadius: '40px',
    background: '#FFF',
    textColor: 'red',
    margin: '0 0rem 0.5rem 0.5rem',
  },
  active: {
    border: '1px solid #75C9EB',
    background: 'rgba(24, 156, 210, 0.08)',
  },
  name: {},
});

const ButtonGroup = ({
  buttons,
  orientation,
  buttonClassName,
  containerClassName,
  activeButtonClassName,
  onButtonClick,
}) => {
  const classes = useStyles();
  const [clickedId, setClickedId] = useState(-1);

  const activeClass = activeButtonClassName || `${classes.active}`;
  const buttonClass = buttonClassName || `${classes.button}`;
  const containerClass =
    containerClassName ||
    (orientation === 'vertical' ? `${styles.vertical}` : `${styles.container}`);

  return (
    <div className={containerClass}>
      {buttons.map((e, i) => (
        <button
          key={i}
          name={e}
          onClick={(event) => {
            setClickedId(i);
            onButtonClick(event);
          }}
          className={
            i === clickedId ? `${buttonClass} ${activeClass}` : `${buttonClass}`
          }
        >
          {<Typography className={classes.name}>{e}</Typography>}
        </button>
      ))}
    </div>
  );
};

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.string).isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  buttonClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  activeButtonClassName: PropTypes.string,
  onButtonClick: PropTypes.func,
};

ButtonGroup.defaultProps = {
  orientation: 'horizontal',
};

export default ButtonGroup;
