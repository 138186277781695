import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '../../Modal';
import useModal from '../../useModal';
import ButtonGroup from '../../ButtonGroup';
import Notice from '../../Notice';

const useStyles = makeStyles(
  () => ({
    header: {
      margin: '20px 0.5rem 8px 0.5rem',
      fontSize: '1.5rem',
      textAlign: 'left',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '140%',
      color: '#2C3B49',
    },
    subHeader: {
      margin: '0px 0.5rem 4px 0.5rem',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '1rem',
      lineHeight: '150%',
      letterSpacing: '0.11px',
      color: '#192129',
    },
    section: {
      fontFamily: 'Montserrat',
      margin: '20px 0.5rem 8px 0.5rem',
      fontSize: '1rem',
      fontWeight: '500',
      textAlign: 'left',
    },
    notice: {
      fontFamily: 'Montserrat',
      margin: '0px 0.5rem 20px 0.5rem',
      fontSize: '1rem',
      fontWeight: '500',
      textAlign: 'left',
      '@media (min-width: 768px)': {
        float: 'right',
      },
    },
    mainContentContainer: {
      fontFamily: 'Montserrat',
      margin: '0 auto',
      maxWidth: '1232px',
      padding: '0 16px',
    },
    duelContainer: {
      fontFamily: 'Montserrat',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '100px 0px',
    },
    cardStyles: {
      fontFamily: 'Montserrat',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      fontFamily: 'Montserrat',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    iconStyle: {
      fontFamily: 'Montserrat',
      color: '#006298',
      margin: '0 auto',
      fontSize: '30px',
    },
    pStyle: {
      fontFamily: 'Montserrat',
      textAlign: 'center',
      fontWeight: '500',
    },
    input: {
      fontFamily: 'Montserrat',
      backgroundColor: '#FFFFFF',
      color: '#006298',
      borderColor: '#006298',
      borderStyle: 'solid',
      padding: '8px 16px',
      borderRadius: '16px',
      margin: '4px 2px',
      '&:hover': {
        backgroundColor: '#eef7fc',
        color: '#179bd3',
        borderColor: '#179bd3',
      },
    },
    submitButton: {
      fontFamily: 'Montserrat',
      backgroundColor: '#006298',
      color: '#FFFFFF',
      borderColor: '#006298',
      borderStyle: 'solid',
      padding: '8px 16px',
      borderRadius: '4px',
      margin: '4px 2px',
      float: 'left',
      '&:disabled': {
        color: 'red',
      },
    },

    formContainer: {},
  }),
  {
    name: 'hwui-UrgentCareVisitSelect',
  }
);

const MammoQuestionaireOpenScheduling = () => {
  const classes = useStyles();
  const { isShowing, toggle } = useModal();

  let questionArray = [false, false, false, false, false, false, false];

  const handleQuestionOneClick = (e) => {
    console.log(e.target.name);
    if (e.target.name === 'Male') {
      toggle();
      questionArray[0] = false;
      console.log(questionArray);
    } else {
      questionArray[0] = true;
      console.log(questionArray);
    }
  };

  const handleQuestionTwoClick = (e) => {
    if (e.target.name === 'Less than 30 years') {
      toggle();
      questionArray[1] = false;
      console.log(questionArray);
    } else {
      questionArray[1] = true;
      console.log(questionArray);
    }
  };

  const handleQuestionThreeClick = (e) => {
    if (e.target.name === 'No') {
      toggle();
      questionArray[2] = false;
      console.log(questionArray);
    } else {
      questionArray[2] = true;
      console.log(questionArray);
    }
  };

  const handleQuestionFourClick = (e) => {
    if (e.target.name === 'No') {
      toggle();
      questionArray[3] = false;
      console.log(questionArray);
    } else {
      questionArray[3] = true;
      console.log(questionArray);
    }
  };

  const handleQuestionFiveClick = (e) => {
    if (e.target.name === 'No' || e.target.name === 'Unknown') {
      toggle();
      questionArray[4] = false;
      console.log(questionArray);
    } else {
      questionArray[4] = true;
      console.log(questionArray);
    }
  };

  const handleQuestionSixClick = (e) => {
    if (e.target.name === 'Yes') {
      questionArray[5] = true;
      console.log(questionArray);
    } else {
      questionArray[5] = true;
      console.log(questionArray);
    }
  };

  const handleQuestionSevenClick = (e) => {
    if (e.target.name === 'Less than 1 year') {
      toggle();
      questionArray[6] = false;
      console.log(questionArray);
    } else {
      questionArray[6] = true;
      console.log(questionArray);
    }
  };

  const isReadyToSubmit = () => {
    if (questionArray === true) return true;
    return false;
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const projectEnvironmnet = urlParams.get('env');
  const projectMode = urlParams.get('mode');

  const vt = urlParams.get('vt');
  const dept = urlParams.get('dept');
  const selRfvId = urlParams.get('selRfvId');
  const selDepId = urlParams.get('selDepId');

  let domain =
    projectEnvironmnet && projectEnvironmnet === 'tst'
      ? 'https://testing-account.adventhealth.com'
      : projectEnvironmnet && projectEnvironmnet === 'poc'
      ? 'https://environment-epic-account.dev.adventhealth.io'
      : projectEnvironmnet && projectEnvironmnet === 'prod'
      ? 'https://account.adventhealth.com'
      : 'https://account.adventhealth.com';

    // Get MyChart Enironment for link.
    let myChartEnv =
    projectEnvironmnet && projectEnvironmnet === 'tst'
        ? 'MyChartTSTDEV'
        : projectEnvironmnet && projectEnvironmnet === 'poc'
          ? 'MyChartPOCDEV'
          : projectEnvironmnet && projectEnvironmnet === 'prod'
            ? 'MyChartPRDDev'
            : 'MyChartPRDDev';


  let mode = projectMode && projectMode === 'auth' ? 'find-care' : 'public';
  let schedulingType =
    projectMode && projectMode === 'auth'
      ? 'direct-scheduling'
      : 'open-scheduling';
  let mammovars =
    projectMode && projectMode === 'auth'
      ? `selRfvId=${selRfvId}&selDepId=${selDepId}`
      : `vt=${vt}&dept=${dept}`;

  // let exitURL = `http://localhost:8000/find-care/scheduling/direct-scheduling/mammogram?selRfvId=${mammoVisitType}&selDepId=1002007042`;
  
  let exitURL = 
    projectMode && projectMode === 'auth'
      ? `${domain}/${mode}/scheduling/${schedulingType}/mammogram?${mammovars}`
      : `${domain}/${myChartEnv}/openscheduling/?specialty=40&${mammovars}`;

  return (
    <div className={classes.mainContentContainer}>
      <Typography variant="h1" className={classes.header}>
        Mammogram Questionnaire
      </Typography>
      <Typography variant="h2" className={classes.subHeader}>
        The following questions will help us determine appointment availability.
      </Typography>

      <br />
      <div className={classes.formContainer}>
        <Typography variant="h3" className={classes.section}>
          What is your legal sex?
        </Typography>
        <ButtonGroup
          buttons={['Male', 'Female']}
          onButtonClick={handleQuestionOneClick}
        />
        <Notice className={classes.notice} isShowing={isShowing} />

        <Typography variant="h3" className={classes.section}>
          How old are you?
        </Typography>
        <ButtonGroup
          buttons={[
            'Greater than 40 years old',
            'Less than 40 years old',
            'Less than 30 years',
          ]}
          onButtonClick={handleQuestionTwoClick}
        />
        <Notice className={classes.notice} />

        <Typography variant="h3" className={classes.section}>
          Do you have an order for this screening mammogram?
        </Typography>
        <ButtonGroup
          buttons={['Yes', 'No']}
          onButtonClick={handleQuestionThreeClick}
        />
        <Notice className={classes.notice} />

        <Typography variant="h3" className={classes.section}>
          Do you have a breast mass, lump, discharge, or pain?
        </Typography>
        <ButtonGroup
          buttons={['Yes', 'No']}
          onButtonClick={handleQuestionFourClick}
        />
        <Notice className={classes.notice} />

        <Typography variant="h3" className={classes.section}>
          Are you Considered High Risk of Breast Cancer by your physician?
        </Typography>

        <ButtonGroup
          buttons={['Yes', 'No', 'Unknown']}
          onButtonClick={handleQuestionFiveClick}
        />
        <Notice className={classes.notice} />

        <Typography variant="h3" className={classes.section}>
          Do you have breast implants?
        </Typography>
        <ButtonGroup
          buttons={['Yes', 'No']}
          onButtonClick={handleQuestionSixClick}
        />
        <Notice className={classes.notice} />

        <Typography variant="h3" className={classes.section}>
          What was the date of your last mammogram?
        </Typography>
        <ButtonGroup
          buttons={['Less than 1 year', 'Greater than 1 year']}
          onButtonClick={handleQuestionSevenClick}
        />
        <Notice className={classes.notice} />

        <br />
      </div>
      <a className={classes.submitButton} href={exitURL} target="_top">
        Submit and continue
      </a>
      {/* <Modal isShowing={isShowing} hide={toggle} /> */}
    </div>
  );
};

export default MammoQuestionaireOpenScheduling;
