import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography, Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '../../../node_modules/centracare-scheduling/registry/css/index.css';
import { faAmbulance, faCalendarPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Visit Selection Screen for Urgent Care

const useStyles = makeStyles(
  () => ({
    header: {
      fontFamily: 'Montserrat',
      margin: '20px 0.5rem 6px 0.5rem',
      fontSize: '1.5rem',
      fontWeight: '500',
      textAlign: 'left',
      color: '#192129',
    },
    mainContentContainer: {
      fontFamily: 'Montserrat',
      margin: '0 auto',
      maxWidth: '1232px',
      padding: '0 16px',
    },
    duelContainer: {
      fontFamily: 'Montserrat',
      display: 'flex',
      columnGap: '24px',
      justifyContent: 'center',
      margin: '22px 0px',
      '@media (max-width: 480px)': {
        flexDirection: 'column',
        rowGap: '24px',
      },
    },
    cardStyles: {
      fontFamily: 'Montserrat',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '45%',
      '@media (max-width: 480px)': {
        width: '100%',
      },
    },
    content: {
      padding: '16px !important',
      fontFamily: 'Montserrat',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    iconStyle: {
      fontFamily: 'Montserrat',
      color: '#006298',
      margin: '0 auto',
      fontSize: '30px',
    },
    pStyle: {
      fontFamily: 'Montserrat',
      textAlign: 'center',
      fontWeight: '500',
      fontSize: '1.5rem',
      color: '#192129',
    },
    button: {
      fontFamily: 'Montserrat',
      backgroundColor: '#006298',
      color: 'white',
      fontSize: '14px',
      fontWeight: '600',
      textTransform: 'none',
      letterSpacing: '0.11px',
      lineHeight: '20px',
      '&:hover': {
        backgroundColor: '#118AC7',
      },
    },
    subheader: {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      color: '#192129',
    },
  }),
  {
    name: 'hwui-UrgentCareVisitSelect',
  }
);

const UrgentCareVisitSelectOpenScheduling = ({ history }) => {
  const classes = useStyles();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const projectEnvironmnet = urlParams.get('env');
  const projectMode = urlParams.get('mode');

  const vt = urlParams.get('vt');
  const dept = urlParams.get('dept');
  const selRfvId = urlParams.get('selRfvId');
  const selDepId = urlParams.get('selDepId');

  let domain =
    projectEnvironmnet && projectEnvironmnet === 'tst'
      ? 'https://testing-account.adventhealth.com'
      : projectEnvironmnet && projectEnvironmnet === 'poc'
        ? 'https://environment-epic-account.dev.adventhealth.io'
        : projectEnvironmnet && projectEnvironmnet === 'prod'
          ? 'https://account.adventhealth.com'
          : 'https://account.adventhealth.com';

  let myChartEnv =
    projectEnvironmnet && projectEnvironmnet === 'tst'
      ? 'MyChartTSTDEV'
      : projectEnvironmnet && projectEnvironmnet === 'poc'
        ? 'MyChartPOCDEV'
        : projectEnvironmnet && projectEnvironmnet === 'prod'
          ? 'MyChartPRDDev'
          : 'MyChartPRDDev';


  let mode = projectMode && projectMode === 'auth' ? 'find-care' : 'public';
  let schedulingType =
    projectMode && projectMode === 'auth'
      ? 'direct-scheduling'
      : 'open-scheduling';
  let directurgentvars =
    projectMode && projectMode === 'auth'
      ? `selRfvId=${selRfvId}&selDepId=${selDepId}`
      : `vt=${vt}&dept=${dept}`;

  // let onMyWayLink = `${domain}/${mode}/scheduling/on-my-way/urgent-care?${directurgentvars}`;
  let onMyWayLink =
    projectMode && projectMode === 'auth'
      ? `${domain}/${mode}/scheduling/on-my-way/urgent-care`
      : `${domain}/${myChartEnv}/scheduling/onmyway`
  // let openSchedulingLink = `${domain}/${mode}/scheduling/${schedulingType}/urgent-care?${directurgentvars}`;
  // let openSchedulingLink = `${domain}/${mode}/scheduling/open-scheduling/urgent-care`;
  let openSchedulingLink = `${domain}/${myChartEnv}/openscheduling?specialty=138&hidespecialtysection=1`;
  return (
    <div className={classes.mainContentContainer}>
      <Typography variant="h1" className={classes.header}>
        How soon will you be coming in?
      </Typography>
      <p className={classes.subheader}>
        Select when you plan to visit Urgent Care so we can direct you
        appropriately:
      </p>
      <div className={classes.duelContainer}>
        <Card className={classes.cardStyles} variant="outlined">
          <CardContent className={classes.content}>
            <FontAwesomeIcon icon={faAmbulance} className={classes.iconStyle} />
            <p className={classes.pStyle}>
              I need to come in as soon as possible (within 48 hours)
            </p>
            <Button
              variant="contained"
              href={onMyWayLink}
              target="_top"
              className={classes.button}
            >
              I&apos;m On My Way
            </Button>
          </CardContent>
        </Card>
        <Card className={classes.cardStyles} variant="outlined">
          <CardContent className={classes.content}>
            <FontAwesomeIcon
              icon={faCalendarPlus}
              className={classes.iconStyle}
            />
            <p className={classes.pStyle}>
              I need to schedule a time in the future (after 48 hours)
            </p>
            <Button
              variant="contained"
              href={openSchedulingLink}
              target="_top"
              className={classes.button}
            >
              Schedule Reservation
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

UrgentCareVisitSelectOpenScheduling.propTypes = {
  history: PropTypes.object,
};

export default withRouter(UrgentCareVisitSelectOpenScheduling);
